import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './error.css';
import { Loader } from '../Loader/Loader';
import { notify } from '../../utils/notify';
import { suggestUserServiceFactory } from '../Services/suggestUserService';

export const SuggestUserContext = createContext();

export const SuggestUserProvider = ({ children }) => { 

  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const suggestUserService = suggestUserServiceFactory();

  const navigate = useNavigate();

  const showErrorAndSetTimeouts = (error) => {
    setErrorMessage(error);
    setIsLoading(false);
    setTimeout(() => {
      setErrorMessage('');
      setIsLoading(false);
    }, 3000);
  };

  const onSuggestSubmit = async (data) => {
    setIsLoading(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await suggestUserService.suggestUser(data);
      navigate('/');
      notify('success-suggest');
    } catch (error) {
      notify('error', error);
      showErrorAndSetTimeouts(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const onSuggestResolve = async (id) => {
    setIsLoading(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await suggestUserService.suggestResolve(id);

      notify('success-approved-suggest');
    } catch (error) {
      notify('error', error);
      showErrorAndSetTimeouts(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllSuggested = async () => {
    setIsLoading(true);
    try {
      const response = await suggestUserService.getAllSuggested();
      return response.userData;
    } catch (error) {
      notify('error', error);
      showErrorAndSetTimeouts(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllResolve= async () => {
    setIsLoading(true);
    try {
      const response = await suggestUserService.getAllResolve();
      return response.userData;
    } catch (error) {
      notify('error', error);
      showErrorAndSetTimeouts(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const onCreateComment = async ({  comment,userId }) => {
    setIsLoading(true);
    try {
      const response = await suggestUserService.createComment( comment,userId);
      notify('success-comment');
      return response.comment;
    } catch (error) {
      notify('error', error);
      showErrorAndSetTimeouts(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getDeleteSuggest = async (id) => {
    setIsLoading(true);
    try {
      const response = await suggestUserService.suggestDelete(id);
      notify('success-delete-suggest')
      return response.userData;
    } catch (error) {
      notify('error', error);
      showErrorAndSetTimeouts(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const contextService = {    
    onSuggestSubmit,
    getAllSuggested,
    getDeleteSuggest,
    onSuggestResolve,
    getAllResolve,
    onCreateComment
  };

  return (
    <SuggestUserContext.Provider value={contextService}>
      {children}
      {isLoading && <Loader />}
    </SuggestUserContext.Provider>
  );
};

export const useSuggestUserContext = () => {
  const context = useContext(SuggestUserContext);

  return context;
};
