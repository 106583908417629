import { useTranslation } from "react-i18next";
import "./communityFooter.css";

export const CommunityFooter = () => {
    // eslint-disable-next-line no-unused-vars
    const {t} = useTranslation();

    return (
        <>
        {/* <footer className="footer-com-fix">
            <div className="footer-menu">
            <section className='footer-privacy-commun'>
                <p>{t('footer.privacy')}</p>
                <p>&copy; {t('footer.copyright')}</p>
            </section>
            </div>
        </footer>
        <MenuCommunity/> */}

        </>
    )
}